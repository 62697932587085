import React, { useState } from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Logo from '../images/header/logo.svg'
import LogoMobile from '../images/header/logo-mobile.svg'
import '../styles/header.scss'

const Header = () => {
	const [showMenu, setShowMenu] = useState(false)

	return (
		<div>
			<div className='container flex items-center justify-between' id='header'>
				<Link to='/'>
					<img src={Logo} alt='logo' className='my-8 lg:!block !hidden' />
					<img
						src={LogoMobile}
						alt='logo'
						className='my-4 lg:!hidden !block h-10'
					/>
				</Link>
				<div className='lg:!hidden block h-8 z-[9999] flex justify-center items-center'>
					<label htmlFor='check' id='menu-icon'>
						<input
							type='checkbox'
							id='check'
							onChange={(e) => {
								setShowMenu(e.target.checked)
							}}
						/>
						<span />
						<span />
						<span />
					</label>
				</div>
				<div
					className={`${
						showMenu ? 'mobile-menu-active' : 'mobile-menu-close'
					} mobile-menu lg:flex lg:flex-row lg:justify-between font-poppins text-small font-medium lg:w-[47%]`}
					id='header-menu'
				>
					<StaticQuery
						query={graphql`
							query MenuItems {
								allPrismicMenuItems(sort: { fields: data___sort }) {
									nodes {
										data {
											title
											sort
											slug
										}
									}
								}
							}
						`}
						render={(data) => (
							<>
								{data.allPrismicMenuItems.nodes.map((item) => {
									return (
										<Link
											to={item.data.slug}
											activeClassName='active-link'
											key={`menu-item-${item.data.title}`}
										>
											{item.data.title}
											<div className='mark absolute bg-cream rounded-full h-4 w-4 -top-2 right-1/2 left-1/2 -translate-x-1/2' />
										</Link>
									)
								})}
							</>
						)}
					/>
				</div>
			</div>
		</div>
	)
}

export default Header
