import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

type Props = {
	title: string
	description: string
	keywords?: string
	path: string
	ogImage?: string
}

const SEO = (props: Props) => {
	const {
		site: { siteMetadata },
	} = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					siteUrl
				}
			}
		}
	`)
	const url = `${siteMetadata.siteUrl}${props.path !== '/' ? props.path : ''}`
	const title = `${props.title ? props.title : siteMetadata.title}`
	const description = `${
		props.description ? props.description : siteMetadata.description
	}`
	const ogImg = props.ogImage
		? props.ogImage
		: `${siteMetadata.siteUrl}/ek-hukuk-og-image.png`

	return (
		<Helmet
			htmlAttributes={{
				lang: 'tr',
			}}
		>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta property='og:type' content='website' />
			<meta property='og:title' content={title} />
			<meta property='og:description' content={description} />
			<meta property='og:url' content={url} />
			<meta name='twitter:card' content='summary' />
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />
			<meta name='twitter:url' content={url} />
			<meta property='og:image' content={ogImg} />
		</Helmet>
	)
}

export default SEO
