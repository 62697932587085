import React, { useState } from 'react'
import { Link } from 'gatsby'
import '../styles/index.scss'
import FooterLogo from '../images/footer/logo.svg'
import WhatsappIcon from '../images/footer/whatsapp.svg'
import InstagramIcon from '../images/footer/instagram.svg'
import TwitterIcon from '../images/footer/twitter.svg'
import FacebookIcon from '../images/footer/facebook.svg'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/Header'

type Props = {
	children: React.ReactNode
	border: boolean
}

const Layout = (props: Props) => {
	return (
		<div className='flex flex-col justify-between'>
			<Header />
			<div className='grow'>{props.children}</div>
			<div className={`${props.border ? 'border-t border-light' : ''}`}>
				<div className='container my-16 font-poppins'>
					<div className='flex lg:flex-row flex-col lg:items-center items-start justify-between'>
						<img src={FooterLogo} alt='' className='mr-6' />
						<div className='flex lg:flex-row flex-col justify-between lg:items-center items-start grow lg:mt-0 mt-6'>
							<div className='text-small'>
								<div>
									Körfez Mah. Berk Sk. Dolphin İş Merkezi Kat:6 No:611
									İzmit/KOCAELİ
								</div>
								<div>
									<a href='tel:0262 303 43 65'>0262 303 43 65</a> -{' '}
									<a href='tel:0530 066 3333'>0530 066 3333</a>
								</div>
							</div>
							<div className='flex lg:mt-0 mt-6'>
								<a
									href='https://wa.me/905300663333'
									target='_blank'
									title='Whatsapp'
									rel='noreferrer'
								>
									<img src={WhatsappIcon} alt='' className='mr-[25.67px]' />
								</a>
								<a
									href='https://www.instagram.com/ekhukukarabuluculuk/'
									target='_blank'
									title='Instagram'
									rel='noreferrer'
								>
									<img src={InstagramIcon} alt='' className='mr-[25.67px]' />
								</a>
								<a
									href='https://www.facebook.com/ekhukukarabuluculuk'
									target='_blank'
									title='Facebook'
									rel='noreferrer'
								>
									<img src={FacebookIcon} alt='' className='mr-[25.67px]' />
								</a>
								<a
									href='https://twitter.com/ekhukuk'
									target='_blank'
									title='Twitter'
									rel='noreferrer'
								>
									<img src={TwitterIcon} alt='' />
								</a>
							</div>
						</div>
					</div>
					<div className='mt-8 lg:ml-[70px] text-lightGrey text-xs'>
						<StaticQuery
							query={graphql`
								query StaticPages {
									allPrismicStaticPages {
										nodes {
											data {
												meta_description
												meta_title
												name
											}
											uid
										}
									}
								}
							`}
							render={(data) => (
								<>
									{data.allPrismicStaticPages.nodes.map((page) => {
										return (
											<Link
												to={`/${page.uid}`}
												className='mr-6'
												key={`${page.data.name}`}
											>
												{page.data.name}
											</Link>
										)
									})}
								</>
							)}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Layout
